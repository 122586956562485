<script setup lang="ts">
import { NavigationId, NavigationItems, filterNavigationItems, userHasAccess } from '@/constants/navigation';
import type { IBaseResponse } from '@/repository/factory';
const { $api, $toast } = useNuxtApp();

const { t } = useI18n();

const mainGroup = filterNavigationItems('main');
const primaryGroup = filterNavigationItems('primary');
const secondaryGroup = filterNavigationItems('secondary');
const tertiaryGroup = filterNavigationItems('tertiary');

const { data: userData } = useUserData();
const { user } = useFirebase();
const hasAdministrationPrivilages = ref(false);

// watch user
watch(user, async (user) => {
	if (!user)
		return;
	hasAdministrationPrivilages.value = await userHasAccess(NavigationItems.find(x => x.id === NavigationId.Administration)!, user);
}, { immediate: true });

const friendCountSession = useFriendCountSession();

onMounted(() => {
	// Refresh friend count every 60 seconds
	let promise: Promise<IBaseResponse<number>> | null = null;

	const pollFriendCount = async () => {
		const now = Date.now();

		if (friendCountSession.value === null || now - (friendCountSession.value?.lastUpdate ?? 0) > 60000) {
			if (promise)
				return;

			let count = 0;
			try {
				promise = $api.friends.getFriendRequestCount();
				const { ok, payload } = await promise;
				if (!ok)
					$toast.error(t('toast.friendsFetchError'));
				count = payload ?? 0;
			}
			finally {
				promise = null;

				friendCountSession.value = {
					count,
					lastUpdate: now,
				};
			}
		}
	};
	pollFriendCount();

	const interval = setInterval(pollFriendCount, 5000);

	onUnmounted(() => {
		clearInterval(interval);
	});
});
</script>

<template>
	<div class="nav-wrapper" v-if="$screen.md">
		<nav class="nav">
			<ul class="nav__list">
				<li class="nav__item" v-for="item in mainGroup" :key="item.id">
					<NuxtLink class="nav__link" :to="{ name: item.name }">
						<Icon :name="item.icon" />
						<span>{{ t(`nav.${item.i18nKey}`) }}</span>
					</NuxtLink>
				</li>
				<li class="nav__separator-wrapper">
					<hr class="nav__separator" />
				</li>
				<li class="nav__item" v-for="item in primaryGroup" :key="item.id">
					<NuxtLink class="nav__link" :to="{ name: item.name }">
						<Icon :name="item.icon" />
						<span>{{ t(`nav.${item.i18nKey}`) }}</span>
					</NuxtLink>
				</li>
				<li class="nav__separator-wrapper">
					<hr class="nav__separator" />
				</li>
				<li class="nav__item" v-for="item in secondaryGroup" :key="item.id">
					<NuxtLink class="nav__link" :to="{ name: item.name }">
						<Icon :name="item.icon" />
						<span>{{ t(`nav.${item.i18nKey}`) }}</span>
						<span class="notification-alert" v-if="item.id === NavigationId.Friends && friendCountSession.count > 0">
							{{ friendCountSession.count }}
						</span>
					</NuxtLink>
				</li>
				<li class="nav__separator-wrapper">
					<hr class="nav__separator" />
				</li>
				<li class="nav__item" v-for="item in tertiaryGroup" :key="item.id">
					<NuxtLink
						class="nav__link"
						:class="{ [`nav__link--${item.severity}`]: item.severity }"
						:to="{ name: item.name }"
						v-if="item.id !== NavigationId.Administration || (item.id === NavigationId.Administration && hasAdministrationPrivilages)"
					>
						<Icon :name="item.icon" />
						<span>{{ t(`nav.${item.i18nKey}`) }}</span>
					</NuxtLink>
				</li>
				<li class="nav__item" v-if="!userData?.isPatreonSupporter">
					<NuxtLink to="https://www.patreon.com/readfolio" target="_blank" class="support-us-card">
						<span class="support-us-card__content">
							<Icon name="social-patreon" />
							<span class="support-us-card__title">{{ $t('actions.supportUs') }}</span>
							<span class="support-us-card__description">{{ $t('props.supportUsContent') }}</span>
							<FirebaseImg class="support-us-card__image" name="rating-simple.webp" role="presentation" />
						</span>
						<Shape name="arrow-1" label="arrow-1" severity="tertiary" />
						<Shape name="misc-3" label="star-4" severity="primary" />
						<Shape name="misc-3" label="star-4" severity="primary" />
						<Shape name="misc-7" label="heart" severity="error" />
					</NuxtLink>
				</li>
			</ul>
		</nav>
	</div>
</template>
