<script setup lang="ts">
const clientSettings = useClientSettings();

onMounted(async () => {
	const accessibilityClassMap: Record<string, string> = {
		highContrastMode: 'html--high-contrast-mode',
		grayscaleMode: 'html--grayscale-mode',
		blackWhiteMode: 'html--black-white-mode',
		dyslexicMode: 'html--dyslexic-mode',
		largeTextMode: 'html--large-text-mode',
		boldTextMode: 'html--bold-text-mode',
		underlineLinksMode: 'html--underline-links-mode',
		animations: 'html--no-animations',
	};

	for (const [setting, className] of Object.entries(accessibilityClassMap)) {
		if (setting === 'animations')
			document.documentElement.classList.toggle(className, !clientSettings.value[setting]); // If the 'animations' is False, add the class
		else
			document.documentElement.classList.toggle(className, Boolean(clientSettings.value[setting]));
	}
});
</script>

<template>
	<NuxtLoadingIndicator :color="false" />
	<Header />
	<div id="app">
		<div class="layout-container">
			<div class="layout layout--profile">
				<Navigation />
				<div class="main-wrapper">
					<main class="main">
						<slot />
					</main>
				</div>
			</div>
		</div>
		<Footer no-start />
	</div>
</template>
